<!--导师列表-->
<template>
  <!--路径s-->
  <div class="path">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :sm="24" :md="22" :lg="20" >
        <span><router-link to="/"> 首页</router-link></span> &nbsp; &gt; &nbsp; 创业导师
      </el-col>
      <el-col :sm="0" :md="1" :lg="2"></el-col>
    </el-row>
  </div>
  <!--路径s--> 
  <div class="box-p">
    <el-row :gutter="0">
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
      <el-col :xs="24" :sm="24" :md="22" :lg="20">
        <el-row :gutter="16">
          <el-col :xs="12" :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/talents/tutor" target="_blank"><img src="@/assets/images/a3.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/talents/tutor" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/talents/tutor" target="_blank"><img src="@/assets/images/a2.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/talents/tutor" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/talents/tutor" target="_blank"><img src="@/assets/images/a1.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/talents/tutor" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/talents/tutor" target="_blank"><img src="@/assets/images/a4.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/talents/tutor" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>          
        </el-row>
        <el-row :gutter="16">
          <el-col :xs="12" :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/talents/tutor" target="_blank"><img src="@/assets/images/a3.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/talents/tutor" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/talents/tutor" target="_blank"><img src="@/assets/images/a2.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/talents/tutor" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/talents/tutor" target="_blank"><img src="@/assets/images/a1.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/talents/tutor" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/talents/tutor" target="_blank"><img src="@/assets/images/a4.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/talents/tutor" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>          
        </el-row>
        <el-row :gutter="16">
          <el-col :xs="12" :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/talents/tutor" target="_blank"><img src="@/assets/images/a3.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/talents/tutor" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/talents/tutor" target="_blank"><img src="@/assets/images/a2.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/talents/tutor" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/talents/tutor" target="_blank"><img src="@/assets/images/a1.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/talents/tutor" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>
          <el-col :xs="12" :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <a href="/talents/tutor" target="_blank"><img src="@/assets/images/a4.jpg" /></a>
              <div class="card-body">
                <div class="card-title1"><a href="/talents/tutor" target="_blank">创业导师</a></div>                
              </div>
            </el-card>
          </el-col>          
        </el-row>
        <div class="pagination">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[10, 15, 20, 30, 40]"
              :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total='tableTotal'>
            </el-pagination>
        </div>        
      </el-col>
      <el-col :xs="0" :sm="0" :md="1" :lg="2" ></el-col>
    </el-row>
  </div> 
</template>

<script>
import {formatDate} from '@/utils/datetime_utils.js'
export default {
  name: 'resourcelist',  
  data () {
    return {
      currentPage: 1,
      pagesize: 20,
      tableTotal: 0,
      listData: []
    }
  },
  mounted () {
    this.getListData()
  },
  methods: {
    // pageSize 新每页条数改变时触发(每页下拉显示数据)
    handleSizeChange (size) {
      this.pagesize = size
      this.getListData()
    },
    // 新当前页改变时触发(点击第几页)
    handleCurrentChange (currentPage) {
      this.currentPage = currentPage
      this.getListData()
    },
    async getListData () {
      // status=1&
      const params = '?size='+ this.pagesize + '&current=' + this.currentPage
      const { data: ret } = await this.$http.get1(this.$api.resourcelist + params)      
      // console.log('ret=', ret)
      if (ret.code === 1) {
        this.listData = ret.data.records
        this.tableTotal = ret.data.total
        // console.log('rows=', this.messageData)
      } else {
        this.$message.error(ret.msg)        
      }     
    },
    // 显示时间格式'yyyy-MM-dd hh:mm:ss'
    formatDate(time){      
      return formatDate(time,'yyyy-MM-dd')
    },
    showImage(val){
      if(val === null) {        
        return '/images/nopic1.jpg'
      } else {        
        var arr = JSON.parse(val)
        var imgurl = this.$api.serverUrl + arr[0].url
        // console.log('IMAGE_URL=', imgurl)       
        return imgurl
      }
    }
  }
}
</script>

<style scoped>
</style>